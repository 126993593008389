
import React from 'react';
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import { StaticQuery, graphql } from "gatsby"


import Layout from '../../../components/layout/Layoutexport';
import CardProductsExport from '../../../components/CardProductsExport'





import { Link } from 'gatsby';



const seo={
  title: "Higiene de manos",
  desc:  "Fabricamos y exportamos productos para la higiene de manos para uso en el hogar y en el trabajo.",
  image: "dispensadores.jpg",
  pathname: "/export/es/higiene-de-manos/",
  alternateEn: "/export/en/hand-hygiene/",
  alternateEs: "/export/es/higiene-de-manos/",
  alternateEsec: "/productos/higiene-de-manos/",
  alternateDefault: "/export/es/higiene-de-manos/"
}

const siteVerOpts = [

  {
    label: 'Internacional',
    value: '',

  },


  {
    label: 'Ecuador',
    value: '/productos/higiene-de-manos/',

  },


];

const langOpts = [

  {
    label: 'ES',
    value: '',

  },


  {
    label: 'EN',
    value: '/export/en/hand-hygiene/',

  },


];




const HigienedeManos = ({location, lang = "es", data}) => {






  return(

          <Layout location={location} crumbLabel="Higiene de manos"
            siteVerOpts={siteVerOpts} langOpts={langOpts}
            alternates alternateEn={seo.alternateEn} alternateEs={seo.alternateEs} alternateEsec={seo.alternateEsec} alternateDefault={seo.alternateDefault}
            title={seo.title} desc={seo.desc} banner={seo.image} pathname={seo.pathname}>

      <section id="heading-banner" className={`mt-2 mb-0 mr-0 border-0 p-0 bg-blue-900`}>
                    <div className="flex flex-row">
                          <div className="grow border-0">
                              <h1 className={`mt-8 ml-2 text-base font-semibold leading-tight tracking-wider py-10
                                              sm:mt-6 sm:ml-6 sm:text-2xl 
                                              md:mt-6 md:ml-12 md:text-2xl md:tracking-wider md:leading-snug
                                              lg:mt-8 lg:text-3xl lg:tracking-wide lg:leading-snug 
                                              xl:mt-8 xl:text-5xl xl:tracking-wider
                                              text-left text-white
                                              `}>
                              Higiene de Manos Profesional
                              </h1>

                          </div>
                          <div className="h-44 lg:h-96 border-0">

                            <StaticImage src="../../../images/banner-higiene-de-manos.png"
                                            className="object-contain h-full"
                                            imgClassName=""


                                            alt="Categoria de productos: Higiene de manos profesional"
                                            placeholder="blurred"
                                            layout="constrained"

                            />
                          </div>


                    </div>
      </section>

      <section id="intro-paragraph">
              <div className="w-full mx-auto mt-0 mb-12 px-2 bg-gray-200">
                <div className="p-6 lg:pt-12 lg:pb-36">
                  <h2 className="my-6 lg:my-8 text-primary-default text-center text-2xl md:text-3xl font-semibold">Productos profesionales para la higiene de manos en el trabajo</h2>
                  <div className="m-0 sm:m-4 lg:w-5/6 lg:mx-auto">
                    <p className="text-2xl font-normal leading-relaxed  text-primary-default mt-6 mb-8  text-md md:text-lg lg:text-xl text-justify">
                      <StaticImage src="../../../images/higiene-de-manos-profesional.jpg"
                                  className={`

                                    mt-2 mb-8 float-none w-full
                                    sm:mt-6 sm:ml-6 sm:mr-0 sm:float-right sm:w-72 sm:mb-4
                                    md:ml-10 md:mb-4
                                    lg:mt-6 lg:w-96 lg:h-48
                                    shadow-md

                                  `}

                                  imgClassName=""
                                  alt="La importancia de la higiene de manos en el trabajo"
                                  placeholder="blurred"
                                  layout="constrained" /><br/>
                        La higiene de manos es un requisito fundamental en todo sistema de calidad e inocuidad alimentaria o de Buenas prácticas de manufactura.
                        Así mismo, en el sector del cuidado de la salud, es un indispensable en todo protocolo de cuidado del paciente.
                        Finalmente, la promoción de una adecuada higiene de manos es, en cualquier entorno laboral, uno de los métodos más efectivos para prevenir contagios y reducir el ausentismo laboral a cause de enfermedades causadas
                        por microbios patógenos. Gracias a nuestros productos, usted podrá implementar un programa adecuado de higiene de manos en su organización.
                    </p>
                  </div>

                </div>
              </div>
</section>

<section id="grid-productos" className="my-12">
    <h3 className="my-6 md:my-8 text-center mx-auto text-primary-lighter text-2xl font-semibold">- Descubra nuestros productos para la higiene de manos profesional -</h3>
    <div className=" bg-white px-4 sm:px-6 lg:pt-6 lg:pb-28 lg:px-8">
     <div className="relative max-w-7xl mx-auto">

      <div className="relative max-w-lg mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3 lg:max-w-none">



      {data.allProducts2CategoryesJson.edges.map( ({node}) => (
        <div key={node.id} className="flex flex-col overflow-hidden hover:bg-zinc-100 rounded-lg hover:shadow-md border">
          <Link to={`/export/${lang}/${node.slug}/`} className="text-lg font-semibold text-center text-primary-lighter hover:text-zinc-500 hover:no-underline">
          <CardProductsExport className="p-6">

              <div className="flex-1">
                <GatsbyImage image={getImage(node.featuredImage)}
                        className="flex justify-center my-4 mx-4"
                        alt={node.title}
                 />
                      <div className="flex flex-wrap mx-auto my-4 justify-center">
                       {node.title}
                     </div>

              </div>

          </CardProductsExport>
          </Link>
        </div>
      ))}



     </div>
    </div>
  </div>
</section>


          </Layout>

        );
}

export const query = graphql`
  query CategoryQuery {
    allProducts2CategoryesJson(filter: {categorySlug: {eq: "higiene-de-manos"}}) {
      edges {
        node {
          featuredImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
            }
          }
          id
          slug
          title
        }
      }
    }
  }
`

export default HigienedeManos;
